.img {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.hero-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.img::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.8;
}

.hero {
    height: 100%;
    width: 100%;
}

.hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.hero .content h1{
    font-size: 4rem;
    padding: 0.6rem 0 1rem;
    text-align: center;
    white-space: nowrap; 

}


.hero .content p{
    font-size: 1.5rem;
    padding: 0.6rem 0 1.5rem;
    text-align: center;
    min-width: 600px;
    max-width: 50vw;
    word-wrap: break-word; /* Add this line */
    margin-top: 2%;


}


.hero .content span{
    color: var(--color-grey-4)
}

.hero .content h2{
    font-size: 2rem;
    font-weight: 200;
}

.hero .content h3{
    font-size: 2rem;
    font-weight: 200;
    text-decoration:solid;
    margin-bottom: 2%;
    margin-top: 10%;
}

.hero .content .buttons {
    margin-bottom: 10%;
    display: flex;
    align-items: center;
    justify-content:center;
}

.hero .content .buttons .social{
    font-size: 2.5rem;
    margin-left: 2rem;
    transition: transform 0.3s ease-in-out; 
}

.hero .content .buttons .social:hover{
    transform: scale(1.2);
}



@media screen and (max-width: 850px) {
    .hero .content h1{
        font-size: 2.3rem;
        padding-bottom: .5rem;
    }

    .hero .content h2{
        font-size: 1.5rem;
        
    }

    .hero .content .buttons .social{
        font-size: 2rem;
        margin-left: 2rem;
        transition: transform 0.3s ease-in-out; 
    }

    .hero .content .buttons .btn{
        padding: 10px 12px;
        min-width: 100px;
    }

    
    
    .hero .content p{
        font-size: 1.2rem;
        min-width: 400px;
        
    }
}

@media screen and (max-width: 400px) {
    
    .hero .content p{
        font-size: 1.2rem;
        min-width: 360px;
        
    }
}