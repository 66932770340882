.timeline {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    max-width: 1600px;
    margin: 3% auto;
    margin-bottom: 5%
}

.timeline .timeline-item {
    position: relative;
    padding-left: 3rem;
    border-left: 1px solid var(--color-grey-5);
}

.timeline .timeline-item .tl-icon {
    position: absolute;
    left: -35px;
    top: 0;
    background-color: var(--color-secondary);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.timeline .timeline-item ul{
    color: var(--color-grey-2);
    font-size: 1.8rem;
    list-style-type: disc;
}

.timeline .timeline-item .tl-duration {
    padding: 0.2rem 0.6rem;
    background-color: var(--color-grey-5);
    border-radius: 15px;
    display: inline-block;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 500;
}


.timeline .timeline-item h5 {
    padding: 1rem 0;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
}
.timeline .timeline-item h5 span {
    color: var(--color-grey-2);
    font-weight: 500;
    font-size: 2rem;
}
.timeline .timeline-item p {
    color: var(--color-grey-2);

}
@media screen and (max-width: 1800px) {
    .timeline .timeline-item ul,
    .timeline .timeline-item h5 span,
    .timeline .timeline-item .tl-duration{
    font-size: 1.03rem;
    }

    .timeline .timeline-item .tl-icon {
        height: 50px;
        width: 50px;
        left: -27px;
        font-size: 1.03rem;

    }

    .timeline .timeline-item h5 {
        font-size: 1.3rem;
    }

    .timeline {
        margin: 3% 8%;
    }
}

@media screen and (max-width: 1040px) {
    .timeline {
        grid-template-columns: 1fr;

    }
}


@media screen and (max-width: 1040px) {
    .timeline {
        padding: 4rem 8%;
        padding-right: 6%;

    }
    .timeline .timeline-item {
        padding-left: 2rem;
    }
}

