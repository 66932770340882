.about-me-container {
    display: flex;
    justify-content: center; 
    align-items: center;
    margin: 3rem 8%; 
    
  }
  
  .about-me-container .image-container {
    width: 30rem; 
    height: 30rem; 
    margin-right: 2rem;
    overflow: hidden; 
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .about-me-container .image-container img {
    border-radius: var(--br-sm02);
    height: 90%;
    width: 80%;
    margin-left: 4rem;
    background-color: var(--color-black);
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }

  .about-me-container .image-container img:hover {
    transform: scale(1.05); /* Increase the scale on hover */
  }


.about-me-container .image-container .background-shape {
    width: 65%;
    height: 100%;
    background-color: var(--color-grey-1);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 46% 0, 79% 100%, 0% 100%);
    clip-path: polygon(0 0, 46% 0, 100% 100%, 0% 100%);
}



  
  .about-me-container .description-container {
    max-width: 600px; 
    margin-left: 3rem;
    margin-top: 2rem;
  }

  .about-me-container .description-container .about-button-container{
    display: flex;
    justify-content: left;
    align-items: center;
    
    margin-top: 2%;
  }

  .about-me-container .description-container .about-button-container .social{
    font-size: 2.5rem;
    margin-left: 2rem;
    transition: transform 0.3s ease-in-out; 
  }
  .about-me-container .description-container .about-button-container .social:hover {
    transform: scale(1.2);
  }
  
  .about-me-container h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
   
  }
  
  .about-me-container p {
    font-size: 1.4rem;
    line-height: 1.6;
    
  }

  @media screen and (min-width: 1801px) {
    .about-me-container {
        margin: 3% 12%; 
    }

    .about-me-container .image-container {
        width: 40rem; 
        height: 40rem; 
    }

    .about-me-container .image-container img {
        height: 95%; 
        width: 85%; 
        margin-left: 6rem; 
    }

    .about-me-container .description-container {
        max-width: 800px; 
        margin-left: 4rem; 
        margin-top: 3rem; 
    }

    .about-me-container h1 {
        font-size: 2rem; 
        margin-bottom: 1.5rem;
    }

    .about-me-container p {
        font-size: 1.6rem;
        line-height: 1.8; 
    }

    .about-me-container .description-container .about-button-container .social {
        font-size: 3rem; 
        margin-left: 3rem; 
    }
}

  

  @media screen and (max-width: 1230px) {
    .about-me-container {
        display: flex;
        flex-direction: column;
        margin: 5% 10%;
      }
    
      
}

@media screen and (max-width: 500px) {
  .about-me-container .description-container{
      margin-left: 3%;
    }
  
    
}