.project-boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5%;
    margin: 3%
  }

.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1200px) {
  .project-boxes {
    grid-template-columns: 1fr;
  }
  .project-container {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1800px) {
  .project-container {
    max-width: 2400px;
    gap: 6%;
  }
  .project-container h1 {
    font-size: 3rem;
  }
}
