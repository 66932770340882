.education {
    display: grid;
    grid-template-columns: 1fr; 
    grid-gap: 2rem;
    
    max-width: 1300px;
    padding-left: 5%;
    margin: 3% auto;
    justify-content: center; 
    align-items: center; 
}

.education .education-item {
    position: relative;
    padding-left: 3rem;
    border-left: 1px solid var(--color-grey-5);
    max-width: 1200px;
}

.education .education-item .education-icon {
    position: absolute;
    left: -35px;
    top: 0;
    background-color: var(--color-secondary);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8rem;
}

.education .education-item .education-duration {
    padding: 0.2rem 0.6rem;
    background-color: var(--color-grey-5);
    border-radius: 15px;
    display: inline-block;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 500;
}


.education .education-item h5 {
    padding: 1rem 0;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
}

.education .education-item p {
    color: var(--color-grey-2);
    font-size: 2rem;
}

@media screen and (max-width: 1800px) {

    .education {
        max-width: 800px;
        padding-left: 10%;
    }

    .education .education-item {
        max-width: 600px;
    }
    
    .education .education-item h5{
        font-size: 1.3rem;
    }

    .education .education-item p,
    .education .education-item .education-duration{
        font-size: 1.1rem;
    }

    .education .education-item .education-icon {
        height: 50px;
        width: 50px;
        left: -27px;
        font-size: 1.03rem;
    }
}

@media screen and (max-width: 700px) {
    .education {
        margin-left: 1rem;
        margin-top: 3rem;
        
    }
    .education .education-item {
        padding-left: 2rem;
    }
}

