/* ProjectBox.css */
.project-box {
    position: relative;
    background-color: var(--color-grey-5);
    border-radius: 5px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease-in-out;
    width: 100%;
}



.project-box .top{
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease-in-out;
    padding-bottom: 5%;
    background-image: url(/public/projBack.jpg);
}



.project-box .bottom{
    border-top: 8px solid var(--color-secondary);
    margin-bottom: 5%;
}

.project-box:hover .top {
    transform: scale(1.1);
}

.project-box h2 {
    text-align: center;
    padding-top: 5%;
}

.project-box .description {
   text-align: center;
   margin: 2% 3%;
}

.project-box .technologies {
    text-align: center;
    margin-top: 5%; 
    transform: scale(1.1);

}

.project-box:hover {
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
    transition: all 0.4s ease-in-out;
}

.project-box .project-tech-stack {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
}

.project-box .project-tech-stack img {
    height: 5rem;
    margin: 0 2%;
    width: auto;
}

@media screen and (min-width: 1800px) {
    .project-box h2 {
        font-size: 2.3rem;
    }
    .project-box .top .technologies {
        font-size: 1.5rem;
    }
    .project-box .project-tech-stack img {
        height: 6rem;
    }
    .project-box .description {
        font-size: 1.5rem;
     }
  }
  

