.section-header {
    text-align: center;
    position: relative;
    margin-top: 5rem;
    padding-top: 7rem;
}

.section-header::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 40%;
    height: 2px;
    background-color: var(--color-grey-5);
    transform: translateX(-50%);
    max-width: 500px;
}

@media screen and (min-width: 1800px) {
    .section-header h1 {
        font-size: 3rem;
    }
}
