
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

body {
  background: var(--color-grey-6);
}

h1, h2, h3, h4, h5, p, a {
  color: var(--color-white);
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 20px;
  min-width: 150px; /* Adjust the fixed width as needed */
  font-size: 1rem;
  text-transform: uppercase;
  background: white;
  color: darkgray;
  border: 1px solid white;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  display: inline-block; /* Ensures that the width is respected even if there's no content */
  text-align: center; /* Center the text horizontally */
}

.btn-light {
  background: transparent;
  color: white;
}

.btn:hover {
  background: lightgray;
  color: white;
  transition: 0.3s;
}

:root {
  --color-primary: #191d2b;
  --color-secondary: #27ae60;
  --color-white: #FFFFFF;
  --color-black: #000;
  --color-grey0: #f8f8f8;
  --color-grey-1: #dbe1e8;
  --color-grey-2: #b2becd;
  --color-grey-3: #6c7983;
  --color-grey-4: #454e56;
  --color-grey-5: #2a2e35;
  --color-grey-6: #12181b;
  --br-sm02: 14px;
  --box-shadow-1: 0 3px 15px rgba(0,0,0,.3) ;
}


/* :root {
  --color-primary: #FDFFF1;
  --color-secondary: #bb4f70;
  --color-white: #454e56;
  --color-black: #000;
  --color-grey0: #f8f8f8;
  --color-grey-1: #6c7983;
  --color-grey-2: #6c7983;
  --color-grey-3: #6c7983;
  --color-grey-4: #FFFFFF;
  --color-grey-5: #f8f8f8;
  --color-grey-6: #12181b;
  --br-sm02: 14px;
  --box-shadow-1: 0 3px 15px rgba(0,0,0,.3) ;
} */
