.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10% 20%;
    padding: 20px;
    text-align: center;
  }
  
  .contact-container h2 {
    margin-bottom: 10px;
    font-size: 2.5rem; 
  }
  
  .contact-container p {
    line-height: 1.5;
    margin-bottom: 20px;
    font-size: 1.5rem; 
  }
  
  .contact-container h3 {
    color: white;
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 30px;
  }


  @media screen and (max-width: 640px) {
    .contact-container {
      margin: 10% 5%;
    }

    .contact-container p {

      font-size: 1.1rem; 
    }

    .contact-container h2 {
      font-size: 2rem; 
    }

    .contact-container h3 {
      font-size: 1.5rem;
    }

    .contact-container .btn-container .btn {
      padding: 10px 12px;
        min-width: 100px;
    }

}

@media screen and (min-width: 1500px) {
  .contact-container {
      margin: 10% 15%;
  }

  .contact-container h2 {
      font-size: 3rem;
  }

  .contact-container p {
      font-size: 1.8rem;
  }

  .contact-container h3 {
      font-size: 2.5rem;
  }

  .contact-container .btn-container .btn {
      padding: 12px 15px;
      min-width: 120px;
  }
}

@media screen and (min-width: 2200px) {
  .contact-container {
      max-width: 1600px;
      margin: 10% auto;
  }

  .contact-container h2 {
      font-size: 4rem;
  }

  .contact-container p {
      font-size: 1.8rem;
  }

  .contact-container h3 {
      font-size: 2.5rem;
  }

  .contact-container .btn-container .btn {
      padding: 12px 15px;
      min-width: 150px;
      font-size: 1.5rem;
  }
}
  
  
  